import React from "react";
import vombyteLogo from "./imgs/vombyte_logo.png";
import construcao from "./imgs/construcao.png";
import imgConstrucao from "./imgs/img-construcao.png";
function App() {
  return (
    <>
      <img
        src={vombyteLogo}
        className="absolute lg:top-2 lg:left-2 left-36 w-28"
        alt="logo"
      />
      <div className="flex lg:flex-row flex-col w-screen h-screen  bg-[url(./imgs/background-mobile.svg)] lg:bg-[url(./imgs/background.svg)] bg-no-repeat lg:bg-[length:110%] lg:bg-desktop-position bg-[center_top_30rem]">
        <div className="flex flex-col mt-32 lg:mt-28 lg:ml-20 lg:flex-left">
          <div className="flex lg:flex-row flex-col lg:items-end items-center gap-x-9">
            <span className="lg:hidden">
              <img src={construcao} alt="Icone construção" className="w-24 mt-5 lg:mt-0" />
            </span>
            <h1 className="text-[#EB1824] font-extrabold lg:text-5xl text-4xl mt-10 lg:mt-0">
              Oops!
            </h1>
            <span className="hidden lg:block">
              <img src={construcao} alt="Icone construção" className="w-32" />
            </span>
          </div>
          <h2 className="font-semibold text-[#274156] lg:text-3xl text-2xl lg:mt-10 mt-10 text-center lg:text-left">
            Website está em construção
          </h2>
          <section className="px-8 lg:px-0 lg:w-2/3 lg:mt-10 mt-3 text-[#626265] lg:text-lg text-md text-justify">
            <p>Em breve começaremos e você poderá nos conhecer melhor.</p>
            <p>
              Estamos trabalhando muito para oferecer uma incrível experiência
              para você.
            </p>
          </section>
        </div>
        <div className="flex lg:justify-end items-end mt-22 lg:mt-0 lg:mr-10 lg:mb-5">
          <img
            src={imgConstrucao}
            className="py-8 w-[87%] md:w-[60%] lg:w-[80%]"
            alt="foto Construção"
          />
        </div>
      </div>
    </>
  );
}

export default App;
